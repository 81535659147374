import loadable from "@loadable/component"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import BrochureForm from "../../../static/forms/off_plan_register_form.json"
import FormLayout from "../FormLayout/FormLayout"
import CloseIcon from "../icons/CloseIcon"
import "./RegisterInterestModal.scss"

const DefaultForm = loadable(() => import("../forms/default-form-layout"))
export const RegisterInterestModal = ({ offplan, show, handleClose }) => {
  const shareurl = typeof window !== "undefined" ? window.location.href : ""
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="register-interest-modal"
    >
      <Modal.Body className="register-interest-modal-body">
        <div className="register-interest-modal-header">
          <div className="content-section">
            <h3 className="title">Register Interest</h3>
            {/* <p className="description">
              Lorem ipsum dolor sit amet consectetur adipiscing elit.
            </p> */}
          </div>
          <CloseIcon onClick={handleClose} />
        </div>
        <FormLayout>
          <DefaultForm
            fields={BrochureForm}
            classname="enquiry-form-wrapper"
            // to_email_id={to_email_id}
            // emailData={emailData}
            prop_url={shareurl}
          />
        </FormLayout>
      </Modal.Body>
    </Modal>
  )
}

const RegisterInterestModalWithTrigger = ({
  offplan,
  buttonText,
  children,
  className,
}) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // if (!offplan?.brochure?.file?.url) return null
  return (
    <>
      <button
        className={className || "button button-primary-color"}
        onClick={handleShow}
      >
        {children || <span>{buttonText || "Register Interest"}</span>}
      </button>
      <RegisterInterestModal
        offplan={offplan}
        show={show}
        handleClose={handleClose}
      />
    </>
  )
}

export default RegisterInterestModalWithTrigger
