import loadable from "@loadable/component"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import BrochureForm from "../../../static/forms/off_plan_download_guide_form.json"
import FormLayout from "../FormLayout/FormLayout"
import CloseIcon from "../icons/CloseIcon"
import "./BrochureModal.scss"

const DefaultForm = loadable(() => import("../forms/default-form-layout"))
export const BrochureModal = ({ offplan, show, handleClose }) => {
  const shareurl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="team-contact-modal"
    >
      <Modal.Body className="team-contact-modal-body">
        <div className="team-contact-modal-header">
          <div className="content-section">
            <h3 className="title">Download Brochure</h3>
            {/* <p className="description">
              Lorem ipsum dolor sit amet consectetur adipiscing elit.
            </p> */}
          </div>
          <CloseIcon onClick={handleClose} />
        </div>
        <FormLayout>
          <DefaultForm
            fields={BrochureForm}
            classname="enquiry-form-wrapper"
            // to_email_id={to_email_id}
            // emailData={emailData}
            downloadfile={offplan?.extra?.brochure_url}
            prop_url={shareurl}
          />
        </FormLayout>
        {/* <BrochureForm offplan={offplan} /> */}
      </Modal.Body>
    </Modal>
  )
}

const BrochureModalWithTrigger = ({
  offplan,
  buttonText,
  children,
  className,
}) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // if (!offplan?.brochure?.file?.url) return null
  return (
    <>
      <button
        className={className || "button button-secondary-outline"}
        onClick={handleShow}
      >
        {children || <span>{buttonText || "Download Brochure"}</span>}
      </button>
      {/* <div id="offplan-brochure"> */}
      <BrochureModal offplan={offplan} show={show} handleClose={handleClose} />
      {/* </div> */}
    </>
  )
}

export default BrochureModalWithTrigger
