import React from "react"
import { Container } from "react-bootstrap"
import "./OffplanAttractions.scss"

const OffplanAttractions = ({ offplan }) => {
  return (
    <div
      className="attractions-offplan-wrap section-l-m"
      id="offplan-attractions"
    >
      <Container className="attractions-offplan-container">
        <h2>Nearby Attractions</h2>
        <ul className="bk">
        {offplan?.extra?.distance_to_the_nearest_school && (
              <li className="item">
                <div className="icon-image" />
                <div className="text-bk">
                    <>
                      <h6>School</h6>
                      <p>{offplan?.extra?.distance_to_the_nearest_school}</p>
                    </>
                </div>
              </li>)}
              {offplan?.extra?.distance_to_the_nearest_beach && (
              <li className="item">
                <div className="icon-image" />
                <div className="text-bk">
                    <>
                      <h6>Beach</h6>
                      <p>{offplan?.extra?.distance_to_the_nearest_beach}</p>
                    </>
                </div>
              </li>
              )}

{offplan?.extra?.distance_to_the_nearest_airport && (
              <li className="item">
                <div className="icon-image" />
                <div className="text-bk">
                    <>
                      <h6>Airport</h6>
                      <p>{offplan?.extra?.distance_to_the_nearest_airport}</p>
                    </>
                </div>
              </li>
              )}

{offplan?.extra?.distance_to_the_nearest_hospital && (
              <li className="item">
                <div className="icon-image" />
                <div className="text-bk">
                    <>
                      <h6>Hospital</h6>
                      <p>{offplan?.extra?.distance_to_the_nearest_hospital}</p>
                    </>
                </div>
              </li>
              )}

{offplan?.extra?.distance_to_the_nearest_university && (
              <li className="item">
                <div className="icon-image" />
                <div className="text-bk">
                    <>
                      <h6>University</h6>
                      <p>{offplan?.extra?.distance_to_the_nearest_university}</p>
                    </>
                </div>
              </li>
              )}

{offplan?.extra?.distance_to_the_nearest_shops && (
              <li className="item">
                <div className="icon-image" />
                <div className="text-bk">
                    <>
                      <h6>Shops</h6>
                      <p>{offplan?.extra?.distance_to_the_nearest_shops}</p>
                    </>
                </div>
              </li>
              )}
{offplan?.extra?.distance_to_city_centre && (
              <li className="item">
                <div className="icon-image" />
                <div className="text-bk">
                    <>
                      <h6>City Centre</h6>
                      <p>{offplan?.extra?.distance_to_city_centre}</p>
                    </>
                </div>
              </li>
              )}

          
        </ul>
      </Container>
    </div>
  )
}

export default OffplanAttractions
