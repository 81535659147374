import clsx from "clsx"
import _ from "lodash"
import React from "react"
import ReactSlider from "react-slick"
import "./Slider.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { ChevronLeft, ChevronRight } from "lucide-react"

const SIZES = {
  sm: {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 3,
  },
  md: {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 4,
  },
  lg: {
    mobile: 1,
    tablet: 2,
    laptop: 4,
    desktop: 4,
  },
  lg2: {
    mobile: 2,
    tablet: 2,
    laptop: 4,
    desktop: 4,
  },
  xl: {
    mobile: 2,
    tablet: 4,
    laptop: 5,
    desktop: 6,
  },
}

const Slider = React.forwardRef(
  (
    {
      children,
      className,
      size,
      darkMode,
      customSettings,
      infinite,
      draggable = true,
    },
    ref
  ) => {
    const variant = _.isEmpty(size) ? SIZES.md : SIZES[size]

    const options = {
      dots: false,
      arrows: false,
      infinite,
      speed: 500,
      slidesToShow: variant.desktop,
      slidesToScroll: 1,
      centerMode: false,
      draggable,
      responsive: [
        {
          breakpoint: 3000,
          settings: {
            slidesToShow: variant.desktop,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
            arrows: false,
          },
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: variant.laptop,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
            arrows: false,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: variant.tablet,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            infinite,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: variant.mobile,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            infinite,
          },
        },
      ],
    }

    return (
      <ReactSlider
        ref={ref}
        className={clsx(
          "custom-slider",
          { "custom-slider-dark": darkMode },
          className
        )}
        {...options}
        {...customSettings}
        id="custom-slider"
      >
        {children}
      </ReactSlider>
    )
  }
)

export const ImageSlider = React.forwardRef(({ children, oneImage }, ref) => {
  const options = {
    dots: false,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    nextArrow: (
      <div>
        <ChevronRight size={24} />
      </div>
    ),
    prevArrow: (
      <div>
        <ChevronLeft size={24} />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: oneImage ? 1 : 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Slider
      ref={ref}
      className="image-slider"
      size="lg"
      customSettings={options}
    >
      {children}
    </Slider>
  )
})

export default Slider
