import * as React from "react"
// import ScrollAnimation from "react-animate-on-scroll"
// import GoogleSingleMap from "../../components/map/google/single"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import GoogleSingleMap from "../maps/google/single"
import "./Map.scss"
// styles

// markup
const Map = (props) => {
  const { isMobile } = useDeviceMedia()
  return (
    props?.property_details_map_data && (
      <div className="property-details-map-wrap" id="map-component">
        {/* <ScrollAnimation animateIn="fadeIn" animateOnce={true}> */}
        {props?.property_details_map_data?.latitude &&
          props?.property_details_map_data?.longitude && (
            <GoogleSingleMap
              className="gg-map"
              lat={props?.property_details_map_data?.latitude}
              lng={props?.property_details_map_data?.longitude}
              height={
                isMobile
                  ? "240px"
                  : props?.property_details_map_data?.department ===
                    "new_development"
                  ? "480px"
                  : props?.areaGuide
                  ? "600px"
                  : "460px"
              }
            />
          )}
        {/* </ScrollAnimation> */}
      </div>
    )
  )
}

export default Map
