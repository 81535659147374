import clsx from "clsx"
import React from "react"

const BedIcon = ({ className }) => (
  <svg
    className={clsx("bed-icon", className)}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_5754_7058)">
      <path
        d="M6.5 10.5V5H13C13.5304 5 14.0391 5.21071 14.4142 5.58579C14.7893 5.96086 15 6.46957 15 7V10.5"
        stroke="#B1B1BF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 13V3"
        stroke="#B1B1BF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 10.5H15V13"
        stroke="#B1B1BF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 5H1"
        stroke="#B1B1BF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5754_7058">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default BedIcon
