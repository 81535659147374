import clsx from "clsx"
import { isEmpty } from "lodash"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import Slider from "react-slick"
import { scrollToID } from "../../lib/utils"
import BrochureModalWithTrigger, {
  BrochureModal,
} from "../BrochureModal/BrochureModal"
import RegisterInterestModalWithTrigger from "../RegisterInterestModal/RegisterInterestModal"
import "./OffplanNavBar.scss"

const NavItem = ({ button, activeButton, setActiveButton, onClick }) => {
  const [isPointMoved, setIsPointMoved] = useState(false)

  const sliderOnSwipe = {
    onPointerDown: (e) => {
      if (e.buttons === 2) return
      setIsPointMoved(false)
    },
    onPointerMove: (e) => {
      if (e.buttons === 2) return
      setIsPointMoved(true)
    },
    onPointerUp: (e) => {
      if (isPointMoved || e.buttons === 2) return

      scrollToID(button.id, 100)
      setTimeout(() => {
        setActiveButton(button)
      }, 500)
    },
  }
  return (
    <button
      className={clsx("nav-bar-item", {
        active: activeButton?.id === button?.id,
      })}
      {...sliderOnSwipe}
      onClick={onClick}
    >
      {button?.title}
    </button>
  )
}

const OffplanNavBar = ({ offplan }) => {
  const {
    description,
    images,
    floor_plans,
    latitude,
    longitude,
    brochure,
    video_module,
    extra,
  } = offplan

  const [isNavTop, setIsNavTop] = React.useState(true)

  const settings = {
    swipeToSlide: true,
    variableWidth: true,
    infinite: false,
    arrows: false,
    dots: false,
  }

  const buttonData = [
    description && {
      title: "Description",
      id: "offplan-details",
    },
    !isEmpty(images) && {
      title: "Image Gallery",
      id: "offplan-gallery",
    },
    latitude &&
    longitude && {
      title: "Location",
      id: "offplan-location",
    },
    !isEmpty(extra?.floor_plan_url) && {
      title: "Floor Plans",
      id: "floor-plans",
      url: extra?.floor_plan_url
    },
    // !isEmpty(amenities) && {
    //   title: "Amenities",
    //   id: "offplan-amenities-slider",
    // },
   
    // !isEmpty(extra?.payment_plan) && {
    //   title: "Payment Plans",
    //   id: "payment-plans",
    // },
    // !isEmpty(extra?.brochure_url) && {
    //     title: "Brochure",
    //     id: "offplan-brochure",
    //     url: extra?.brochure_url
    //   },
      !isEmpty(extra?.payment_plan_url) && {
        title: "Payment Plan",
        id: "payment-plan-url",
        url: extra?.payment_plan_url
      },
      !isEmpty(extra?.fact_sheet_url) && {
        title: "Fact sheet",
        id: "fact-sheet",
        url: extra?.fact_sheet_url
      },
    // (video_module?.thumbnail || video_module?.video_url) && {
    //   title: "Video",
    //   id: "offplan-video",
    // },
  ].filter(Boolean)

  const [activeButton, setActiveButton] = React.useState(buttonData[0])

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // eslint-disable-next-line prefer-destructuring
            const id = entry.target.id
            const button = buttonData.find((_button) => _button.id === id)
            setActiveButton(button)
          }
        })
      },
      { threshold: 0.5 }
    )

    buttonData.forEach((button) => {
      const target = document.getElementById(button.id)
      if (target) {
        observer.observe(target)
      }
    })

    const handleScroll = () => {
      const navBar = document.querySelector(".offplan-nav-bar-wrap")
      if (navBar) {
        const { top } = navBar.getBoundingClientRect()
        if (top > 0) {
          setIsNavTop(false)
        } else {
          setIsNavTop(true)
        }
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      buttonData.forEach((button) => {
        const target = document.getElementById(button.id)
        if (target) {
          observer.unobserve(target)
        }
      })

      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  const [show, setShow] = useState(false)
  const openLink = (url) => window.open(url, '_blank')?.focus();
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <div
      className={clsx("offplan-nav-bar-wrap", {
        "offplan-nav-bar-wrap--top": isNavTop,
      })}
    >
      <Container className="offplan-nav-bar-container">
        <Slider {...settings}>
          {buttonData.map((_button) => (
            <NavItem
              key={_button.id}
              button={_button}
              activeButton={activeButton}
              setActiveButton={setActiveButton}
              onClick={_button.title === "Fact sheet" || _button.title === "Payment Plan" || _button.title === "Brochure" || _button.title === "Floor Plans" ? () => openLink(_button?.url) : null}
              
            />
          ))}
        </Slider>

        <div className="cta-section">
          <BrochureModalWithTrigger offplan={offplan} />
          <BrochureModal
            offplan={offplan}
            show={show}
            handleClose={handleClose}
          />
          <RegisterInterestModalWithTrigger offplan={offplan} />
        </div>
      </Container>
    </div>
  )
}

export default OffplanNavBar
