import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Row } from "react-bootstrap"
import "./assets/styles/_index.scss"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")
const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/dist/Modules/CTALinkModule")

const OffPlanTileBlock = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiCommunity {
        edges {
          node {
            tail_image {
              url
            }
            title
            strapi_id
            slug
            description {
              data {
                description
              }
            }
          }
        }
      }
    }
  `)
  const filtered = data?.allStrapiCommunity?.edges?.filter(
    (item) => item?.node?.slug === props?.slug
  )
  // console.log(props?.slug)
  // console.log(filtered)
  const imagename = "community.tile_section_image.tail_image"

  let processedImages = JSON.stringify({})
  if (
    filtered?.length > 0 &&
    filtered[0]?.node?.imagetransforms?.tail_section_image_Transforms
  ) {
    processedImages =
      filtered[0]?.node?.imagetransforms?.tail_section_image_Transforms
  }
  return (
    filtered?.length > 0 && (
      <section
        className={`tile-block-wrapper section-p ${
          props?.background_color_transparent ? "transparent" : ""
        }    ${props?.event ? "event" : ""}`}
      >
        <Container>
          <Row className="d-flex align-items-center">
            <Col xl={6}>
              <div className="img-wrapper tile-img-wrapper">
                <ScrollAnimation
                  animateIn="animate__fadeIn"
                  delay={100}
                  animateOnce
                  offset={50}
                >
                  <ImageModule
                    ImageSrc={filtered[0]?.node?.tail_image}
                    altText={filtered[0]?.node?.tail_image?.alternativeText}
                    imagetransforms={processedImages}
                    renderer="srcSet"
                    imagename={imagename}
                    strapi_id={filtered[0]?.node?.strapi_id}
                    classNames="img-fluid"
                  />
                  {/* <img
                  src={filtered[0]?.node?.tail_image?.url}
                  alt="area guide image"
                  classNames="img-fluid"
                /> */}
                </ScrollAnimation>
              </div>
            </Col>
            <Col xl={1} />
            <Col xl={5}>
              <ScrollAnimation
                animateIn="animate__slideInUp"
                animateOnce
                delay={100}
                offset={100}
              >
                {/* {filtered[0]?.node.title && <h6>{filtered[0]?.node.title}</h6>} */}
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__slideInUp"
                animateOnce
                delay={200}
                offset={100}
              >
                {filtered[0]?.node?.title && (
                  <h2>{filtered[0]?.node?.title}</h2>
                )}
              </ScrollAnimation>
              {filtered[0]?.node?.description && (
                <ScrollAnimation
                  animateIn="animate__slideInUp"
                  delay={100}
                  animateOnce
                  offset={50}
                >
                  <ContentModule
                    Content={filtered[0]?.node?.description?.data?.description}
                  />
                </ScrollAnimation>
              )}
              {props?.cta_link && (
                <ScrollAnimation
                  animateIn="animate__slideInUp"
                  delay={300}
                  animateOnce
                  offset={50}
                >
                  <CTALink
                    class="button button-secondary-outline"
                    link={props.cta_link}
                    title={props.cta_title}
                    target_window={props.cta_link.target_window}
                  />
                </ScrollAnimation>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    )
  )
}

export default OffPlanTileBlock
