import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Row } from "react-bootstrap"
import Map from "../PropertyMap/Map"
import "./assets/styles/_index.scss"
import RegisterInterestModalWithTrigger from "../RegisterInterestModal/RegisterInterestModal"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")
const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/dist/Modules/CTALinkModule")

const TileBlockMap = (props) => {
  const locationInfo = {
    latitude: parseFloat(props.latitude),
    longitude: parseFloat(props.longitude),
  }

  return (
    <section
      className={`tile-block-right-wrapper section-p ${
        props?.background_color_transparent ? "transparent" : ""
      } ${props?.event ? "event" : ""}`}
      id="offplan-location"
    >
      <Container>
        <Row className="d-flex align-items-center">
          <Col xl={5} className="order-xl-1 order-2">
            {/* <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={100}
              offset={100}
            >
              {props?.primary_heading && <h6>{props?.primary_heading}</h6>}
            </ScrollAnimation> */}
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={200}
              offset={100}
            >
              {props?.secondary_heading && <h2>{props?.secondary_heading}</h2>}
            </ScrollAnimation>
            {props?.description && (
              <ScrollAnimation
                animateIn="animate__slideInUp"
                delay={100}
                animateOnce
                offset={50}
              >
                <div className="description">
                  <ContentModule Content={props?.description} />
                </div>
                <div className="register_btn_map">
                  <RegisterInterestModalWithTrigger offplan={props?.offplan} />
                </div>
              </ScrollAnimation>
            )}
            {/* {props?.cta_link && (
              <ScrollAnimation
                animateIn="animate__slideInUp"
                delay={300}
                animateOnce
                offset={50}
              >
                <CTALink
                  class="button button-primary"
                  link={props?.cta_link}
                  title={props?.cta_title}
                  target_window={props?.cta_link.target_window}
                />
              </ScrollAnimation>
            )} */}
          </Col>
          <Col xl={1} className="order-xl-2" />
          <Col xl={6} className="order-xl-3 order-1">
            <div className="campaign-map-location">
              <Map property_details_map_data={locationInfo} />
            </div>
          </Col>

        </Row>
      </Container>
    </section>
  )
}

export default TileBlockMap
