import { Link, navigate } from "gatsby"
import React from "react"
import { Breadcrumb, Col, Container, Row } from "react-bootstrap"
import "./assets/styles/_index.scss"

const BreadcrumbModule = (props) => {
  let sublink = ""
  if (props.tag === "menu") {
    if (props.parentlink) {
      sublink = `${props.parentlink}/${props.subparentlink}`
    } else {
      sublink = props.subparentlink
    }
  } else {
    sublink = props.subparentlink
  }
  return (
    <div
      className={`${
        props?.isproperty
          ? "breadcrumb-module-wrapper property-details"
          : "breadcrumb-module-wrapper"
      }`}
    >
      <Container>
        <Row>
          <Col>
            <Breadcrumb>
              {props?.isproperty && (
                <li className="breadcrumb-item back-btn">
                  <a href="javascript:;" onClick={() => navigate(-1)}>
                    Back to Search Listings
                  </a>
                </li>
              )}

              <li
                className={`${
                  props?.isproperty ? "breadcrumb-item line" : "breadcrumb-item"
                }`}
              >
                <Link to="/">Home</Link>
              </li>
              {props.parentlink && (
                <li className="breadcrumb-item">
                  <Link to={`/${props.parentlink}/`}>{props.parentlabel}</Link>
                </li>
              )}
              {props.subparentlink && (
                <li className="breadcrumb-item">
                  <Link to={`/${sublink}/`}>{props.subparentlabel}</Link>
                </li>
              )}
              <li className="breadcrumb-item active">{props.title}</li>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BreadcrumbModule
