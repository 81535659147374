import loadable from "@loadable/component"
import { Link } from "gatsby"
import { capitalize } from "lodash"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import noImg from "../../images/noImage.png"
import { formatNumber } from "../../lib/utils"
import { useGlobalStore } from "../../store/globalStore"
import { calcPrice } from "../CurrencyConverter/CurrConvertPrice"
import { sliderSettings } from "../SliderSettings/SliderSettings"
import BedIcon from "../icons/BedIcon"
import LocationIcon from "../icons/LocationIcon"
import "./assets/styles/_index.scss"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")

const Slider = loadable(() => import("react-slick"))

const SimilarOffPlanProperties = (props) => {
  const { currencyType } = useGlobalStore()
  const [propItems, setPropItems] = useState(props?.allProjects)

  const imagename = "plugin::stb-feed-qobrix.qobrix-project.images.results"
  return (
    <>
      {props.showDividerLine && <div className="divider-line" />}
      {propItems && propItems.length > 0 && (
        <section className="similar-properties-wrapper">
          <Container>
            <Row>
              <Col>
                <h5>{props.title}</h5>
              </Col>
            </Row>
            <Row>
              <Slider className="similar-properties-slider" {...sliderSettings}>
                {propItems.map(({ node }, i) => {
                  const newhome_details_path = "/off-plan-project"
                  const details_path = ""
                  // if (property.search_type == "lettings") {
                  //   details_path = "/property-for-rent"
                  // }

                  let processedImages = JSON.stringify({})

                  if (node?.imagetransforms?.images_Transforms) {
                    processedImages = node?.imagetransforms?.images_Transforms
                  }
                  const image_all = node?.images

                  let detailUrl = ""
                  if (props.tag === "new-homes") {
                    detailUrl = `${newhome_details_path}/${node?.slug}/${node?.strapi_id}/`
                  }
                  return (
                    <div className="similar-properties-slide" key={i}>
                      <div className="item-similar">
                        <div className="similar-properties-img-zoom img-wrapper">
                          <Link to={detailUrl}>
                            {/* <img src={image_all[0].url} className="img-fluid" alt="Madisonfox" /> */}
                            {image_all && image_all?.length > 0 ? (
                              <ImageModule
                                ImageSrc={image_all?.[0]}
                                altText="Baytify"
                                imagetransforms={processedImages}
                                renderer="srcSet"
                                imagename={imagename}
                                strapi_id={node?.strapi_id}
                                classNames="img-fluid"
                              />
                              // <img
                              //   classNames="img-fluid"
                              //   src={image_all[0]?.srcUrl}
                              // />
                            ) : (
                              <img src={noImg} alt="Baytify" />
                            )}
                          </Link>
                        </div>
                        <div className="content-section">
                          {node?.price > 0 ? (
                            <Link to={detailUrl} className="price">
                              {/* AED {formatNumber(price)} */}
                              {/* {CurrConvertPrice(
                              node?.price?.toLocaleString()
                            )}{" "} */}
                              {currencyType?.value}{" "}
                              {parseInt(
                                calcPrice(
                                  formatNumber(node?.price),
                                  currencyType?.value,
                                  "AED"
                                )
                              ).toLocaleString()}{" "}
                              {node?.price_qualifier}
                            </Link>
                          ) : null}
                          {node?.status && (
                            <Link to={detailUrl} className="ammenities">
                              {capitalize(node?.status?.replace(/_/g, " "))}
                            </Link>
                          )}

                          {node?.bedroom ? (
                            <div className="info-section">
                              <p className="bedrooms">
                                <BedIcon />
                                <span>
                                  {node?.bedroom}
                                  <span className="d-none d-xl-inline">
                                    {" "}
                                    {node?.bedroom > 1 ? "Beds" : "Bed"}
                                  </span>
                                </span>
                              </p>
                            </div>
                          ) : (
                            <div className="info-section no-border" />
                          )}

                          {node?.display_address && (
                            <p className="address">
                              <LocationIcon /> {node?.display_address}
                            </p>
                          )}
                        </div>
                        {/* <div className="similar-properties-content-wrapper">
                        {props.tag === "new-homes" ? (
                          <div className="similar-properties-price">
                            <Link to={detailUrl}>
                              {node.displayaddress}
                            </Link>
                          </div>
                        ) : (
                          <div className="similar-properties-price">
                            {node.price_qualifier}£
                            {node.price.toLocaleString()}
                          </div>
                        )}

                        {props.tag === "new-homes" ? (
                          <div className="similar-properties-displayaddress">
                            {node.price_qualifier}£
                            {node.price.toLocaleString()}
                          </div>
                        ) : (
                          <div className="similar-properties-displayaddress">
                            <Link to={detailUrl}>
                              {node.display_address}
                            </Link>
                          </div>
                        )}
                        <div className="similar-properties-title">
                          {node.title}
                        </div>
                        <div className="similar-properties-key-list">
                          <ul className="list-inline">
                            {props.tag === "new-homes" ? (
                              <>
                                {node.bedroom > 0 && (
                                  <li className="list-inline-item">
                                    <div className="d-flex align-items-center">
                                      <i className="icon icon-similar-bed"></i>
                                      <span>{node.bedroom} Bedrooms</span>
                                    </div>
                                  </li>
                                )}
                              </>
                            ) : (
                              <>
                                {node.bedroom > 0 && (
                                  <li className="list-inline-item">
                                    <div className="d-flex align-items-center">
                                      <i className="icon icon-similar-bed"></i>
                                      <span>{node.bedroom}</span>
                                    </div>
                                  </li>
                                )}
                                {node.bathroom > 0 && (
                                  <li className="list-inline-item">
                                    <div className="d-flex align-items-center">
                                      <i className="icon icon-similar-bath"></i>
                                      <span>{node.bathroom}</span>
                                    </div>
                                  </li>
                                )}
                                {node.reception > 0 && (
                                  <li className="list-inline-item">
                                    <div className="d-flex align-items-center">
                                      <i className="icon icon-similar-reception"></i>
                                      <span>{node.reception}</span>
                                    </div>
                                  </li>
                                )}
                              </>
                            )}
                          </ul>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </Row>
          </Container>
        </section>
      )}
    </>
  )
}

// export const query = graphql`
//   query ($strapi_id: String) {
//     allProjects(
//       filter: { publish: { eq: true }, crm_id: { ne: $strapi_id } }
//       limit: 3
//     ) {
//       edges {
//         node {
//           images {
//             srcUrl
//           }
//           slug
//           id
//           title
//           display_address
//           price
//           price_qualifier
//           bedroom
//           status
//           crm_id
//         }
//       }
//     }
//   }
// `

export default SimilarOffPlanProperties
