import loadable from "@loadable/component"
import FsLightbox from "fslightbox-react"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { ImageSlider } from "../elements/Slider/Slider"
import "./OffplanImages.scss"

const PlayVideo = loadable(() => import("../PlayVideo/PlayVideo"))

const Image = ({
  image,
  index,
  setSelectedImage,
  setToggler,
  toggler,
  title,
  processedImages,
  strapiID,
  videoUrl,
  setPlay,
}) => {
  const [isPointMoved, setIsPointMoved] = useState(false)

  const { isMobile, isTablet,isTabletOnly } = useDeviceMedia()

  const sliderOnSwipe = {
    onPointerDown: (e) => {
      if (e.buttons === 2) return
      setIsPointMoved(false)
    },
    onPointerMove: (e) => {
      if (e.buttons === 2) return
      setIsPointMoved(true)
    },
    onPointerUp: (e) => {
      if (isPointMoved || e.buttons === 2) return

    //{(isMobile || isTablet) && setToggler(!toggler)}

      setSelectedImage(index)
    },
  }

  return (
    <div className="image-item img-zoom" key={image.srcUrl} {...sliderOnSwipe}>
      <img src={image.srcUrl} alt={image.caption} />
      {/* <GGFXImage
        ImageSrc={image}
        altText={`${title} image ${index}`}
        imagetransforms={processedImages}
        renderer="srcSet"
        imagename="off-plan.images.gallery_image"
        strapiID={strapiID}
      /> */}
      {/* <img src={image.url} alt={`${title} image ${index}`} /> */}

      {index === 1 && videoUrl && !isTablet &&(
          <button onClick={()=>{setPlay(true)}} className="video-play-btn" />
      )}

      {index === 2 && !isTablet&& (
        <button onClick={()=>{setToggler(!toggler)}} className="button button-primary-color offplan-btn">
          <span><i className="icon gallery-black-icon"/> </span>
          <span className="view-gallery">View Gallery</span>
        </button>
      )
      }
      {index === 1 && videoUrl && isTabletOnly && (
        <button onClick={()=>{setPlay(true)}} className="button button-primary-color offplan-video-btn">
          <span><i className="icon icon-property-detail-video"/> </span>
          <span className="view-gallery">Video</span>
        </button>
      )
      }
      {index === 0 && videoUrl && isMobile && (
        <button onClick={()=>{setPlay(true)}} className="button button-primary-color offplan-video-btn">
          <span><i className="icon icon-property-detail-video"/> </span>
          <span className="view-gallery">Video</span>
        </button>
      )
      }
    </div>
  )
}

const OffplanImages = ({ offplan }) => {
  const { images, imagetransforms, title, strapi_id, video_tour } = offplan

  let processedImages = imagetransforms?.image_Transforms
  processedImages ||= JSON.stringify({})

  const [toggler, setToggler] = React.useState(false)
  const [selectedImage, setSelectedImage] = React.useState(0)
  const imageUrls = images?.map((image) => image.srcUrl)
  const [isPlay, setPlay] = useState(false)

  const { isMobile, isTablet } = useDeviceMedia()

  // eslint-disable-next-line no-nested-ternary
  const imagesToDisplay = isMobile ? 1 : isTablet ? 2 : 3

  return (
    <div className="offplan-images-wrap section-l-m" id="offplan-gallery">
      <Container className="offplan-images-container">
        <div className="images-grid-wrap">
          {isTablet ? (
            <ImageSlider>
              {images.map((image, index) => (
                <Image
                  key={image.url}
                  image={image}
                  index={index}
                  setSelectedImage={setSelectedImage}
                  toggler={toggler}
                  setToggler={setToggler}
                  title={title}
                  processedImages={processedImages}
                  strapiID={strapi_id}
                  videoUrl={video_tour?.[0]?.srcUrl}
                  setPlay={setPlay}
                />
              ))}
            </ImageSlider>
          ) : (
            <div className="images-grid">
              {images?.slice(0, imagesToDisplay).map((image, index) => (
                <Image
                  key={image.url}
                  image={image}
                  index={index}
                  setSelectedImage={setSelectedImage}
                  toggler={toggler}
                  setToggler={setToggler}
                  title={title}
                  processedImages={processedImages}
                  strapiID={strapi_id}
                  videoUrl={video_tour?.[0]?.srcUrl}
                  setPlay={setPlay}
                />
              ))}
            </div>
          )}
          {/* <BrochureModalWithTrigger
            buttonText="Download 4K Images"
            className="button button-gray trigger-button"
            offplan={offplan}
          /> */}
        </div>
      </Container>
      <FsLightbox
        toggler={toggler}
        sources={imageUrls}
        sourceIndex={selectedImage}
        type="image"
      />
      {isPlay && (
        <PlayVideo
          isOpen={isPlay}
          stopPlay={setPlay}
          videoId=""
          isCloseFunction={setPlay}
          videourl={video_tour[0].srcUrl}
          htmlink=""
        />
      )}
    </div>
  )
}

export default OffplanImages
