import React from "react"
import { Container } from "react-bootstrap"
import BrochureModalWithTrigger from "../BrochureModal/BrochureModal"
import RegisterInterestModalWithTrigger from "../RegisterInterestModal/RegisterInterestModal"
import "./BannerOffplan.scss"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")

const BannerOffplan = ({ offplan }) => {
  // console.log(offplan)
  const { title, images, imagetransforms, strapi_id } = offplan

  let processedImages = imagetransforms?.images_Transforms
  processedImages ||= JSON.stringify({})
  const imagename = "plugin::stb-feed-qobrix.qobrix-project.images.banner"

  return (
    <div className="offplan-banner-wrap">
      <div className="bg-section">
        {/* <GGFXImage
          ImageSrc={images && images?.length > 0 && images[0].srcUrl}
          altText={title}
          imagetransforms={processedImages}
          renderer="srcSet"
          imagename="off-plan.banner_image.main_img"
          strapiID={strapi_id}
        /> */}
        {/* <img
          src={images && images?.length > 0 && images[0].srcUrl}
          alt={title}
        /> */}
        {/* {console.log(images && images?.length > 0 && images[0])}
        {console.log(processedImages)}
        {console.log(imagename)}
        {console.log(strapi_id)} */}
        <ImageModule
          ImageSrc={images && images?.length > 0 && images[0]}
          altText="Baytify"
          imagetransforms={processedImages}
          renderer="srcSet"
          // lazyLoading={i != 0}
          imagename={imagename}
          strapi_id={strapi_id}
          classNames="img-fluid"
        />
        <div className="overlay" />
      </div>
      <Container className="offplan-banner-container">
        <div className="offplan-banner-section">
          <div className="content-section">
            <h1 className="title">{title}</h1>
            <p className="developer">
              by <span>{offplan?.extra?.developer_name}</span>
            </p>
            <div className="cta-section">
              <RegisterInterestModalWithTrigger offplan={offplan} />
              <BrochureModalWithTrigger offplan={offplan} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default BannerOffplan
